import * as React from 'react';
import { withPrismicPreviewResolver } from 'gatsby-plugin-prismic-previews';

import Layout from '../components/layout';

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return 'Not a preview!';

  return (
    <Layout>
      <p>Loading</p>
    </Layout>
  );
};

export default withPrismicPreviewResolver(PreviewPage);
